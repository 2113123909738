////////////////////////////////////////////////
////////////////////////////////////////////////
// 1. Initial variables

// Colors
$black:        hsl(0, 0%, 4%);
$black-bis:    hsl(0, 0%, 7%);
$black-ter:    hsl(0, 0%, 14%);

$grey-darker:  hsl(0, 0%, 21%);
$grey-dark:    hsl(0, 0%, 29%);
$grey:         hsl(0, 0%, 48%);
$grey-light:   hsl(0, 0%, 71%);
$grey-lighter: hsl(0, 0%, 86%);

$white-ter:    hsl(0, 0%, 96%);
$white-bis:    hsl(0, 0%, 98%);
$white:        hsl(0, 0%, 100%);

$orange:       hsl(14,  100%, 53%);
$yellow:       hsl(48,  100%, 67%);
$green:        hsl(141, 71%,  48%);
$turquoise:    hsl(171, 100%, 41%);
$blue:         hsl(217, 71%,  53%);
$purple:       hsl(271, 100%, 71%);
$red:          hsl(348, 100%, 61%);

// Typography
$family-sans-serif: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue", "Helvetica", "Arial", sans-serif;
$family-monospace: "Inconsolata", "Consolas", "Monaco", monospace;

$size-1: 3.5rem;
$size-2: 2.75rem;
$size-3: 2rem;
$size-4: 1.5rem;
$size-5: 1.25rem;
$size-6: 14px;
$size-7: 0.75rem;

$weight-light: 300;
$weight-normal: 400;
$weight-semibold: 500;
$weight-bold: 700;

// Miscellaneous
$easing: ease-out;
$radius-small: 2px;
$radius: 3px;
$radius-large: 5px;
$speed: 86ms;

////////////////////////////////////////////////
////////////////////////////////////////////////
// 2. Primary colors

$primary: $turquoise;

$info: $blue;
$success: $green;
$warning: $yellow;
$danger: $red;

$light: $white-ter;
$dark: $grey-darker;


